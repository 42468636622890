import actions from './actions';
import { helpers } from '../../helpers';
import settings from '../../shared/settings';

const logger = new helpers.Logger('Settings.reducers', settings.logger.reducers);

const settingsReducers = (state = settings.defaultState.settings, action) => {
	switch (action.type) {
		case actions.types.setLanguage:
			logger.redux('setLanguage', 'payload', action.payload);
			return { ...state, language: action.payload };

		case actions.types.setLogoutTimeout:
			logger.redux('setLogoutTimeout', 'payload', action.payload);
			return { ...state, logoutTimeout: action.payload };

		case actions.types.setToggleIDs:
			logger.redux('setToggleIDs', 'payload', action.payload);
			return { ...state, toggleIDs: action.payload };

		default:
			return state;
	}
};

export default settingsReducers;
