import actions from './actions';
import { helpers } from '../../helpers';
import settings from '../../shared/settings';

const logger = new helpers.Logger('Users.reducers', settings.logger.reducers);

const usersReducers = (state = settings.defaultState.users, action) => {
	switch (action.type) {
		case actions.types.cacheList:
			logger.redux('cacheList', 'payload', action.payload);
			return { ...state, list: action.payload };

		default:
			return state;
	}
};

export default usersReducers;
