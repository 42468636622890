import React, { useState } from "react"
import { Alert } from "react-bootstrap"
import "./ToastNotification.scss"
const NS = "ToastNotification"

const ToastNotification = (props) => {
	const [show, setShow] = useState(true)

	const toogleShow = () => {
		setShow(false)
		props.closeAlert(props.error.type)
	}

	return (
		<div className={NS}>
			<Alert variant={props.error.style} onClose={toogleShow} show={show} dismissible>
				<p>{props.error.message}</p>
			</Alert>
		</div>
	)
}

// DefaultProps
ToastNotification.defaultProps = {}


export default ToastNotification
