import { createAction } from 'redux-actions';

const actionTypes = {
	cacheList: 'cacheList_SERVICES',
};

const actions = {
	types: actionTypes,
	cacheList: createAction(actionTypes.cacheList),
};

export default actions;
