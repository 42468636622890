// Libs
import React, { lazy, Suspense, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { ConnectedRouter } from "connected-react-router";

// Local
import { history } from "./store";
import settings from "./settings";
import type {
  users,
  instances,
} from "../../serverts/prisma/prisma-client-js/index";

// Public views
import Login from "../scenes/Login/Login";
import ResetPassword from "../scenes/ResetPassword/ResetPassword";
import Support from "../scenes/Support/Support";
import { InstanceSelectorContext } from "../helpers/context/instance-selector-context";

// Private views
const Providers = lazy(() => import("../scenes/Providers/Providers"));
const ProviderCategories = lazy(
  () => import("../scenes/ProviderCategories/ProviderCategories")
);
const Regions = lazy(() => import("../scenes/Regions/Regions"));
const Services = lazy(() => import("../scenes/Services/Services"));
const ServiceCategories = lazy(
  () => import("../scenes/ServiceCategories/ServiceCategories")
);
// const Settings = lazy(() => import('../scenes/Settings/Settings'));
const Skeleton = lazy(() => import("../components/layout/Skeleton/Skeleton"));
const Users = lazy(() => import("../scenes/Users/Users"));

const Sync = lazy(() => import("../scenes/Sync/Sync"));

interface RouterProps {
  user: users;
}

const AppRouter = (props: RouterProps) => {
  const [instance, setInstance] = useState<instances>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  // Variables
  const redux = {
    user: props.user,
  };
  const { routes } = settings;

  return (
    <InstanceSelectorContext.Provider
      value={{ instance, setInstance, isDisabled, setIsDisabled }}
    >
      <ConnectedRouter history={history}>
        {redux.user ? (
          <Suspense fallback="">
            <Routes>
              {/* LOGGED IN */}
              {/* Core */}
              <Route
                path={routes.providers}
                element={
                  <Skeleton {...props}>
                    <Providers {...props} />
                  </Skeleton>
                }
              >
                <Route
                  path={":id"}
                  element={
                    <Skeleton {...props}>
                      <Providers {...props} />
                    </Skeleton>
                  }
                />
              </Route>
              <Route
                path={routes.providerCategories}
                element={
                  <Skeleton {...props}>
                    <ProviderCategories {...props} />
                  </Skeleton>
                }
              >
                <Route
                  path={":id"}
                  element={
                    <Skeleton {...props}>
                      <ProviderCategories {...props} />
                    </Skeleton>
                  }
                />
              </Route>
              <Route
                path={routes.regions}
                element={
                  <Skeleton {...props}>
                    <Regions {...props} />
                  </Skeleton>
                }
              >
                <Route
                  path={":id"}
                  element={
                    <Skeleton {...props}>
                      <Regions {...props} />
                    </Skeleton>
                  }
                />
              </Route>
              <Route
                path={routes.serviceCategories}
                element={
                  <Skeleton {...props}>
                    <ServiceCategories {...props} />
                  </Skeleton>
                }
              >
                <Route
                  path={":id"}
                  element={
                    <Skeleton {...props}>
                      <ServiceCategories {...props} />
                    </Skeleton>
                  }
                />
              </Route>
              <Route
                path={routes.services}
                element={
                  <Skeleton {...props}>
                    <Services {...props} />
                  </Skeleton>
                }
              >
                <Route
                  path={":id"}
                  element={
                    <Skeleton {...props}>
                      <Services {...props} />
                    </Skeleton>
                  }
                />
              </Route>
              <Route
                path={routes.users}
                element={
                  <Skeleton {...props}>
                    <Users {...props} />
                  </Skeleton>
                }
              >
                <Route
                  path={":id"}
                  element={
                    <Skeleton {...props}>
                      <Users {...props} />
                    </Skeleton>
                  }
                />
              </Route>
              <Route
                path={routes.sync}
                element={
                  <Skeleton {...props}>
                    <Sync {...props} />
                  </Skeleton>
                }
              />

              {/* Defaults */}
              <Route
                path="/"
                element={<Navigate replace to={routes.services} />}
              />
              <Route element={<Navigate replace to="/" />} />
            </Routes>
          </Suspense>
        ) : (
          <Routes>
            {/* NOT LOGGED IN */}
            <Route path="/" element={<Login {...props} />} />
            <Route
              path={routes.resetPassword + "/:token"}
              element={<ResetPassword {...props} />}
            />
            <Route path={routes.support} element={<Support {...props} />} />
            <Route element={<Navigate replace to="/" />} />
          </Routes>
        )}
      </ConnectedRouter>
    </InstanceSelectorContext.Provider>
  );
};
const mapStateToProps = (state) => ({
  user: state.login.user,
});

export default connect(mapStateToProps)(AppRouter);
