// Libs
import React from "react";
import { Image, Button, Col, Container, Form, Row } from "react-bootstrap";

//translations
import languages from './languages.json';
import i18n from '../../shared/i18n';
import { useTranslation } from 'react-i18next';

// Local
import defaultData from "./defaultData.json";
import logo from "../Login/assets/logo-sp.png";

// Styles
import "./Support.scss";

// Namespace
const NS = "Support";
/**
 * @class
 * @description
 */
const Support = (props) => {
	
	// Translations
	(i18n as any).customLoad(languages, NS);
	const { t } = useTranslation(NS);

	return (
		<div className={NS}>
			<div className="reset-wrapper">
				<Image className="logo" src={logo} />

				<h1>{t("title")}</h1>
				<h5>{t("description")}</h5>
				<Form className="rp-form" >
					<Container fluid>
						<Row>
							<Col>
								<Form.Group>
									<Button
										variant="success"
										className="save-button"
										size="lg"
										onClick={() => window.open(defaultData.twilioURL, "_blank")}
									>
										{t("twilioButton")}
									</Button>
								</Form.Group>
							</Col>
						</Row>
						<Row>
							<Col>
								<Form.Group>
									<Button
										variant="success"
										className="save-button"
										size="lg"
										onClick={() => window.open(defaultData.cmsURL, "_blank")}
									>
										{t("CMSButton")}
									</Button>
								</Form.Group>
							</Col>
						</Row>

						<Row>
							<Col>
								<Form.Group>
									<Button
										variant="success"
										className="save-button"
										size="lg"
										onClick={() => window.open(defaultData.zendeskURL, "_blank")}
									>
										{t("ZendeskButton")}
									</Button>
								</Form.Group>
							</Col>
						</Row>
					</Container>
				</Form>
			</div>
			<div className="footer">
				<span>{t("footer")}</span>
			</div>
		</div>
	);
};

export default Support;

