import { createAction } from 'redux-actions';

const actionTypes = {
	setResultsPerPage: 'setResultsPerPage_SKELETON',
	setSidebarNavOpen: 'setSidebarNavOpen_SKELETON',
	setShowFilter: 'setShowFilter_SKELETON',
};

const actions = {
	types: actionTypes,
	setResultsPerPage: createAction(actionTypes.setResultsPerPage),
	setSidebarNavOpen: createAction(actionTypes.setSidebarNavOpen),
	setShowFilter: createAction(actionTypes.setShowFilter),
};

export default actions;
