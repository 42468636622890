export const emailValidation = (email) => {
	const regex =
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
	return email.match(regex);
};

export const passwordValidation = (password) => {
	const regex =
		/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*?[=;:.,_{|°}#?!@$%^&*-]).{8,64}$/;
	return password.match(regex);
};

export const phoneValidation = (phone) => {
	const regex =
		/^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/;
	return regex.test(phone);
};

export const instagramValidation = (instagram) => {
	const regex = /(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9_.]{1,30}\/?/;
	return regex.test(instagram);
};

export const twitterValidation = (twitter) => {
	const regex =
		/(https?:\/\/)?(www\.)?twitter\.com\/[A-Za-z0-9_]{5,15}(\?(\w+=\w+&?)*)?/;
	return regex.test(twitter);
};

export const facebookValidation = (facebook) => {
	const regex = /((http|https):\/\/|)(www\.|)facebook\.com\/[a-zA-Z0-9.]{1,}/;
	return regex.test(facebook);
};

export const websiteValidation = (web) => {
	const regex =
	//eslint-disable-next-line
		/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
	return regex.test(web);
};
