// Local
import { helpers } from "../../helpers";
import settings from "../../shared/settings";

const METHODS = { GET: "GET", POST: "POST" };

interface apiInterface {
  login?: {
	login: (body: any, reject: any, success: any, error: any) => Promise<void>,
	instances: {
		getAll: (body: any, reject: any, success: any, error: any) => Promise<void>
	}
  };
};

let api: apiInterface = {}
api.login = {
  /**
   * @method
   * @description
   */
  login: (body, reject, success, error) => {
    const endpoint = `${settings.backend.url}/auth/login`;
    return helpers.api.request(
      METHODS.POST,
      body,
      endpoint,
      reject,
      success,
      error
    );
  },
  instances: helpers.api.crudFactory(
    settings.backend.url,
    settings.routes.instances
  ),
};

export default api;
