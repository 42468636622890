// Libs
import React, { useState } from "react";
import { Image, Button, Col, Container, Form, Row } from "react-bootstrap";
import logo from "../Login/assets/logo-sp.png";

// Local
import api from "./api";
import defaultData from "./defaultData.json";
import ToastNotification from "../../components/ToastNotification/ToastNotification";
import { emailValidation, passwordValidation } from "../../helpers/validations";

//translations
import languages from "./languages.json";
import i18n from "../../shared/i18n";
import { useTranslation } from "react-i18next";

// Styles
import "./ResetPassword.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ServicesParams } from "../../shared/constants";
// Namespace
const NS = "ResetPassword";

/**
 * @class
 * @description
 */
const ResetPassword = (props) => {
  const errArray = [
    {
      id: 0,
      name: "password",
      error: false,
    },
    {
      id: 1,
      name: "password2",
      error: false,
    },
    {
      id: 3,
      name: "email",
      error: false,
    },
  ];
  // States
  const [data, setData] = useState(defaultData);
  const [error, setError] = useState(errArray);
  const [alert, setAlert] = useState([]);

  // Translations
  (i18n as any).customLoad(languages, NS);
  const { t } = useTranslation(NS);

  // Variables
  let params = useParams<ServicesParams>();
  let navigate = useNavigate();

  const saveUserErrMsg = {
    type: "saveUser",
    style: "danger",
    message: t("error.saveUser"),
  };

  /**
   * @method
   * @description
   */
  const back = () => {
    navigate("/");
  };

  /**
   * @method
   * @description
   */
  const onChange = (event) => {
    event.persist();
    setData((data) => ({
      ...data,
      [event.target.name]: event.target.value,
    }));
  };

  /**
   * @method
   * @description
   */
  const onSave = (e) => {
    e.preventDefault();
    const { email, password, password2 } = data;
    if (!email || !password || !password2) return;
    data.token = params.token;
    api.users.resetPassword(
      data,
      () => null,
      (success) => back(),
      (e) => onShowAlert(saveUserErrMsg)
    );
  };

  /**
   * @method
   * @description
   */
  const validateEmail = (email) => {
    !emailValidation(email)
      ? addError("email", true)
      : addError("email", false);
  };

  const addError = (name, err) => {
    let newList = [...error];
    let index = newList.findIndex((x) => x.name === name);
    newList[index]["error"] = err;
    setError(newList);
  };

  const getError = (name) => {
    return error.filter((x) => x.name === name)[0].error;
  };

  const validateErrors = () => {
    return error.filter((x) => !!x.error).length;
  };

  /**
   * @method
   * @description
   */
  const validatePass = (isPassword2) => {
    const { password, password2 } = data;
    if (isPassword2) {
      password !== password2
        ? addError("password2", true)
        : addError("password2", false);
    } else {
      !passwordValidation(password)
        ? addError("password", true)
        : addError("password", false);
      !!password2.length && password !== password2
        ? addError("password2", true)
        : addError("password2", false);
    }
  };

  const onShowAlert = (alert) => {
    setAlert((oldAlert) => [...oldAlert, alert]);
  };

  const onCloseAlert = (type) => {
    setAlert(alert.filter((error) => error.type !== type));
  };

  return (
    <div className={NS}>
      {alert.length > 0 &&
        alert.map((error, i) => (
          <ToastNotification
            showAlert={onShowAlert}
            closeAlert={onCloseAlert}
            error={error}
            key={`${i} ${error.type}`}
          />
        ))}
      <div className="reset-wrapper">
        <Image className="logo" src={logo} />

        <h1>{t("title")}</h1>

        <Form className="rp-form" onSubmit={onSave}>
          <Container fluid>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder={t("email")}
                    onChange={(e) => onChange(e)}
                    defaultValue={data.email}
                    className={getError("email") && "error"}
                    onBlur={(e) => validateEmail(e.target.value)}
                    required
                  />
                </Form.Group>
                {getError("email") && (
                  <span className="error-message">
                    Please enter a valid email.
                    <p></p>
                  </span>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group
                  className={getError("password") && "error-container"}
                >
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder={t("password")}
                    onChange={(e) => onChange(e)}
                    value={props.contactEmail}
                    onBlur={(e) => validatePass(false)}
                    className={getError("password") && "error"}
                    required
                  />
                </Form.Group>
                {getError("password") && (
                  <span className="error-message">
                    Please enter a valid password. Must contain between 8 and 64
                    characters. One lower case, one upper case, one number and
                    one symbol.
                    <p></p>
                  </span>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group
                  className={getError("password2") && "error-container"}
                >
                  <Form.Control
                    type="password"
                    name="password2"
                    placeholder={t("password2")}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={props.contactEmail}
                    onBlur={(e) => validatePass(true)}
                    className={getError("password2") && "error"}
                    required
                  />
                </Form.Group>
                {getError("password2") && (
                  <span className="error-message">
                    The passwords do not match.
                    <p></p>
                  </span>
                )}
              </Col>
            </Row>

            <Row>
              <Col>
                <Button
                  className="save-button"
                  variant="primary"
                  type="submit"
                  disabled={
                    !(data.email && data.password && data.password2) ||
                    !!validateErrors()
                  }
                >
                  {t("submit")}
                </Button>
              </Col>
            </Row>
          </Container>
        </Form>
      </div>
      <div className="foot-notes">
        <p>{t("footNotes.problems")}</p>
        <p>{t("footNotes.contact")}</p>
      </div>

      <div className="footer">
        <span>{t("footer")}</span>
      </div>
    </div>
  );
};

export default ResetPassword;
