// Libs
import React, {
	useEffect,
	useState,
	useContext
} from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import {
	Alert,
	Button,
	Form,
	Image,
} from 'react-bootstrap'

// Local
import actions from './actions'
import api from './api'
import i18n from '../../shared/i18n'
import languages from './languages.json'
import logo from './assets/logo-sp.png'
import { InstanceSelectorContext } from "../../helpers/context/instance-selector-context"

// Styles
import './Login.scss'

// Namespace
const NS = 'Login'

/**
 * @class
 * @description Login scene
 */
export const Login = props => {
	(i18n as any).customLoad(languages, NS)
	const { t } = useTranslation(NS)

	// States
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [instanceId] = useState(0)
	const [instances, setInstances] = useState([])
	const [wait, setWait] = useState(false)
	const { setInstance } = useContext(InstanceSelectorContext)

	// Variables
	const redux = {
		alertMessage: props.alertMessage,
		alertVariant: props.alertVariant,
		// methods
		setAlertMessage: props.setAlertMessage,
		setAlertVariant: props.setAlertVariant,
		setTimedOut: props.setTimedOut,
		setToken: props.setToken,
		setUser: props.setUser,
	}

	/**
	 * @method
	 * @description
	 */
	const onSubmit = e => {
		e.preventDefault()
		setWait(true)
		login()
	}

	const mapAlertMessages = statusCode => {
		const errorCodesMap = [
			{ code: 401, message: 'Invalid email address and/or password. Try again or contact your support team.' },
			{ code: 500, message: 'Connection lost' },
		]

		const mappedCode = errorCodesMap.filter(ec => statusCode === ec.code)
		return mappedCode.length ? mappedCode[0].message : 'Error N/A'
	}

	/**
	 * @method
	 * @description
	 */
	const onChangeEmail = e => {
		const regex = /^[\w.]+@\w+(\.\w+){1,2}$/
		regex.test(e) ? setEmail(e) : setEmail('')
	}

	/**
	 * @method
	 * @description
	 */
	const onChangePassword = p => setPassword(p)

	/**
	 * @method
	 * @description
	 */
	const login = () => {
		const error = err => { redux.setAlertMessage(err); redux.setAlertVariant('danger'); setWait(false) }
		const reject = res => { redux.setAlertMessage(mapAlertMessages(res.status)); redux.setAlertVariant('danger'); setWait(false) }
		const success = res => {
			if (res.token) {
				redux.setTimedOut(false)
				redux.setToken(res.token)
				redux.setUser(res.user)
				setInstance(res.user.instanceId)
			} else {
				redux.setAlertMessage(res.message) // Unauthorized
			}
		}

		api.login.login({ email, password, instance: instanceId }, reject, success, error)
	}

	/**
	 * @async
	 * @description 
	 */
	useEffect(() => {
		// Async API requests
		(async function fetchData() {
			const successInstances = res => {
				setInstances(res.sort((a, b) => a.slug.localeCompare(b.slug)))
			}

			await api.login.instances.getAll(null, () => null, successInstances, () => null)
		})()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className={NS}>
			<Alert show={!!redux.alertMessage.length} onClose={() => redux.setAlertMessage('')} variant={redux.alertVariant} dismissible>{redux.alertMessage}</Alert>
			<div className='login-wrapper'>
				<Image className='logo' src={logo} />

				<h1>{t('title')}</h1>

				<h5>{t('subtitle')}</h5>

				<Form onSubmit={onSubmit}>
					<Form.Group>
						<Form.Control className='email' type='email' placeholder={t('email')} onChange={e => onChangeEmail(e.target.value)} />
					</Form.Group>

					<Form.Group>
						<Form.Control className='password' type='password' placeholder={t('password')} onChange={e => onChangePassword(e.target.value)} />
					</Form.Group>

					<Button className='login-button' variant='primary' type='submit' disabled={wait || !(email && password)}>
						{t('submit')}
					</Button>
				</Form>

				<div className='foot-notes'>
					<p>{t('footNotes.problems')}</p>
					<p>{t('footNotes.contact')}</p>
				</div>

				<div className='footer'><span>{t('footer')}</span></div>
			</div>
		</div>
	)
}

const mapStateToProps = (state, props) => ({
	alertMessage: state.login.alertMessage,
	alertVariant: state.login.alertVariant,
})

const mapDispatchToProps = dispatch => ({
	setAlertMessage: message => dispatch(actions.setAlertMessage(typeof message === "string" ? message : message?.message ?? "Error")),
	setAlertVariant: variant => dispatch(actions.setAlertVariant(variant)),
	setTimedOut: timedOut => dispatch(actions.setTimedOut(timedOut)),
	setToken: token => dispatch(actions.setToken(token)),
	setUser: user => dispatch(actions.setUser(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
