import type { PrismaClient, users, instances, cities, city_i18ns, countries, country_i18ns, files, nc_evolutions, provider_i18ns, providercategories, providercategory_i18ns, providers, region_i18ns, regions, service_i18ns, servicecategories, servicecategory_i18ns, servicecontactinformation, servicecontactinformationtype, servicedocumentlink, serviceopeninghours, services, services_categories, twilio, synclogs } from '../../serverts/prisma/prisma-client-js/index'
import store from '../shared/store'
type Prisma = Omit<PrismaClient, "$connect" | "$disconnect" | "$executeRaw" | "$executeRawUnsafe" | "$on" | "$queryRaw" | "$queryRawUnsafe" | "$transaction" | "$use">

interface PrismaRequest {
	m: any
	f: string
	p: any
}

declare global {
	type User = users
	type Instance = instances
	type City = cities
	type City_i18ns = city_i18ns
	type Country = countries
	type Country_i18ns = country_i18ns
	type FileItem = files
	type NC_Evolution = nc_evolutions
	type Provider_i18ns = provider_i18ns
	type ProviderCategory = providercategories
	type ProviderCategory_i18ns = providercategory_i18ns
	type Provider = providers
	type Region_i18ns = region_i18ns
	type Region = regions
	type Service_i18ns = service_i18ns
	type ServiceCategory = servicecategories
	type ServiceCategory_i18ns = servicecategory_i18ns
	type ServiceContactInformation = servicecontactinformation
	type ServiceContactInformationType = servicecontactinformationtype
	type ServiceDocumentLink = servicedocumentlink
	type ServiceOpeningHour = serviceopeninghours
	type Service = services
	type Service_Category = services_categories
	type Twilio = twilio
}



async function callBackend(r: PrismaRequest) {
	const state = store.getState()

	return fetch("/api/data", {
		headers: {
			'Accept': 'application/json',
			'Authorization': state.login.token,
			'Content-Type': 'application/json',
			'Content-Language': 'en',
			'X-Requested-With': 'XMLHttpRequest', // for security reasons
		},
		body: JSON.stringify(r),
		method: "POST",
	}).then(async res => {
		if (res.status === 200) return res.json()
		const text = await res.text()
		console.log("Prisma Error: ", text)

		throw text
	})
}

export function model<M extends keyof Prisma>(m: M): Prisma[M] {
	const proxy = {
		aggregate: async (p: any) => callBackend({ f: "aggregate", m, p }),
		count: async (p: any) => callBackend({ f: "count", m, p }),
		create: async (p: any) => callBackend({ f: "create", m, p }),
		createMany: async (p: any) => callBackend({ f: "createMany", m, p }),
		delete: async (p: any) => callBackend({ f: "delete", m, p }),
		deleteMany: async (p: any) => callBackend({ f: "deleteMany", m, p }),
		findFirst: async (p: any) => callBackend({ f: "findFirst", m, p }),

		findMany: async (p: any) => {
			return callBackend({ f: "findMany", m, p })
		},

		findUnique: async (p: any) => callBackend({ f: "findUnique", m, p }),
		groupBy: async (p: any) => callBackend({ f: "groupBy", m, p }),
		update: async (p: any) => callBackend({ f: "update", m, p }),
		updateMany: async (p: any) => callBackend({ f: "updateMany", m, p }),
		upsert: async (p: any) => callBackend({ f: "upsert", m, p }),
	}
	return proxy as any
}


type ArrayOrT<T extends any[] | any> = T extends (infer U)[] ? U : T
type ArrayOrTReturn<T extends any[] | any, R> = T extends any[] ? R[] : R

function project<T extends any[] | any, R>(v: T, t: (i: ArrayOrT<T>) => R): ArrayOrTReturn<T, R> {
	if (Array.isArray(v)) {
		return v.map(t) as any
	} else {
		return t(v as any) as any
	}
}

export const data = {
	cities: model('cities'),
	city_i18ns: model('city_i18ns'),
	countries: model('countries'),
	country_i18ns: model('country_i18ns'),

	files: model('files'),
	instances: model('instances'),
	nc_evolutions: model('nc_evolutions'),

	provider_i18ns: model('provider_i18ns'),
	providercategories: model('providercategories'),
	providercategory_i18ns: model('providercategory_i18ns'),
	providers: model('providers'),

	region_i18ns: model('region_i18ns'),
	regions: model('regions'),

	service_i18ns: model('service_i18ns'),
	servicecategories: model('servicecategories'),
	servicecategory_i18ns: model('servicecategory_i18ns'),
	servicecontactinformation: model('servicecontactinformation'),
	servicecontactinformationtype: model('servicecontactinformationtype'),
	servicedocumentlink: model('servicedocumentlink'),
	serviceopeninghours: model('serviceopeninghours'),
	services: model('services'),
	synclogs: model('synclogs'),
	// services_categories: model('services_categories'),

	twilio: model('twilio'),
	users: model('users'),

	project,

}






