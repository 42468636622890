import { createAction } from 'redux-actions';

const actionTypes = {
	cacheCitiesList: 'cacheCitiesList_REGIONS',
	cacheCountriesList: 'cacheCountriesList_REGIONS',
	cacheRegionsList: 'cacheRegionsList_REGIONS',
	setActiveTab: 'setActiveTab_REGIONS',
};

const actions = {
	types: actionTypes,
	cacheCitiesList: createAction(actionTypes.cacheCitiesList),
	cacheCountriesList: createAction(actionTypes.cacheCountriesList),
	cacheRegionsList: createAction(actionTypes.cacheRegionsList),
	setActiveTab: createAction(actionTypes.setActiveTab),
};

export default actions;
