import actions from './actions'
import { helpers } from '../../helpers'
import settings from '../../shared/settings'

const logger = new helpers.Logger('Login.reducers', settings.logger.reducers)

const loginReducers = (state = settings.defaultState.login, action) => {
	switch (action.type) {
		case actions.types.setAlertMessage:
			logger.redux('setAlertMessage', 'payload', action.payload)
			return { ...state, alertMessage: action.payload }

		case actions.types.setAlertVariant:
			logger.redux('setAlertVariant', 'payload', action.payload)
			return { ...state, alertVariant: action.payload }

		case actions.types.setTimedOut:
			logger.redux('setTimedOut', 'payload', action.payload)
			return { ...state, timedOut: action.payload }

		case actions.types.setToken:
			logger.redux('setToken', 'payload', action.payload)
			return { ...state, token: action.payload }

		case actions.types.setUser:
			logger.redux('setUser', 'payload', action.payload)
			return { ...state, user: action.payload }

		// case actions.types.setUserRole:
		// 	logger.redux('setUserRole', 'payload', action.payload);
		// 	const user = { ...state.user, role: action.payload };
		// 	return { ...state, user };

		default:
			return state
	}
}

export default loginReducers
