import { createAction } from 'redux-actions';

const actionTypes = {
	setAlertMessage: 'setAlertMessage_LOGIN',
	setAlertVariant: 'setAlertVariant_LOGIN',
	setTimedOut: 'setTimedOut_LOGIN',
	setToken: 'setToken_LOGIN',
	setUser: 'setUser_LOGIN',
};

const actions = {
	types: actionTypes,
	setAlertMessage: createAction(actionTypes.setAlertMessage),
	setAlertVariant: createAction(actionTypes.setAlertVariant),
	setTimedOut: createAction(actionTypes.setTimedOut),
	setToken: createAction(actionTypes.setToken),
	setUser: createAction(actionTypes.setUser),
};

export default actions;
