import { createAction } from 'redux-actions';

const actionTypes = {
	setLanguage: 'setLanguage_SETTINGS',
	setLogoutTimeout: 'setLogoutTimeout_SETTINGS',
	setToggleIDs: 'setToggleIDs_SETTINGS',
};

const actions = {
	types: actionTypes,
	setLanguage: createAction(actionTypes.setLanguage),
	setLogoutTimeout: createAction(actionTypes.setLogoutTimeout),
	setToggleIDs: createAction(actionTypes.setToggleIDs),
};

export default actions;
