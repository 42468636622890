// Libs

// Local

/**
 * @function
 * @description
 */
const filterCities_EditView = (regionId, cities) => cities.filter(c => {
	if (!c.region) {
		console.warn('City with null region', c)
		return false
	}
	return +regionId === c.region.id
})

/**
 * @function
 * @description
 */
const filterProviderCategories_EditView = (countryId, providerCategories) => providerCategories.filter(p => {
	if (!p.country) {
		console.warn('Provider Category with null country', p)
		return false
	}

	return +countryId === p.country.id
})

/**
 * @function
 * @description
 */
const filterServiceCategories_EditView = (countryId, serviceCategories) => (serviceCategories || []).filter(s => {
	if (!s.country) {
		console.warn('Service Category with null country', s)
		return false
	}

	return +countryId === s.country.id
})

/**
 * @function
 * @description
 */
const filterRegions_EditView = (countryId, regions) => (regions || []).filter(r => {
	if (!r.country) {
		console.warn('Region with null country', r)
		return false
	}

	return +countryId === r.country.id
})

const sortDates = (a, b, order, dataField, rowA, rowB) => {
	if (order === 'asc') {
		return Date.parse(a) - Date.parse(b)
	}
	else if (order === 'desc') {
		return Date.parse(b) - Date.parse(a)
	}
}

/**
 * @function
 * @param {string} name The string name to generate the slug
 * @description Replaces spaces by hyphens
 */
const generateSlug_EditView = name => name.toLowerCase().replace(/[&/()–'.,:;’? ]/g, '')
/**
 * @method
 * @description
 */
const getMainName_EditView = (data, countries) => {
	let country = countries ? countries.find(c => c.id === data.countryId) : null
	let countryLang = (country && country.preferredLanguage) ? country.preferredLanguage : "en"
	let i18nsData = data.data_i18n ? (data.data_i18n.find(trans => trans.language === countryLang && !!trans.name.length) || data.data_i18n.find(trans => trans.language === 'en')) : null
	return i18nsData ? i18nsData.name : (data.name ? data.name : "")
}

/**
* @function
* @description
*/
const setFocusById_EditView = id => setTimeout(() => {
	const input = document.getElementById(`${id}`)
	input && input.focus()
}, 0)

/**
 * @function
 * @description
 */
// TODO: How to customize sort carets -> https://github.com/react-bootstrap-table/react-bootstrap-table2/issues/177#issuecomment-417101572
const createColumn_ListView = (dataField, text, sort, headerStyle, sortFunc = null) => ({
	dataField,
	text,
	sort,
	sortFunc: (dataField === 'updatedAt' || dataField === 'date') ? sortDates : sortFunc,
	...(headerStyle && { headerStyle: () => (headerStyle) }),
})

/**
 * @function
 * @description
 */
const setFocus_ListView = () => setTimeout(() => {
	const input = document.getElementById('search-bar-0')
	input && input.focus()
}, 0)

const ui = {
	EditView: {
		filterCities: filterCities_EditView,
		filterRegions: filterRegions_EditView,
		filterProviderCategories: filterProviderCategories_EditView,
		filterServiceCategories: filterServiceCategories_EditView,
		generateSlug: generateSlug_EditView,
		getMainName: getMainName_EditView,
		setFocusById: setFocusById_EditView,
	},
	ListView: {
		createColumn: createColumn_ListView,
		setFocus: setFocus_ListView,
	},
}

export { ui }
