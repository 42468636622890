const settings = {
	backend: {
		url: '/api',
		cdn: 'https://refugeecdn.blob.core.windows.net/$web',
		// url: 'http://d3ee92e90204.ngrok.io/api',
	},

	defaultState: {
		login: {
			alertMessage: '',
			alertVariant: 'danger',
			timedOut: false,
			token: null,
			user: null,
			// { // NOTE: user default data
			// 	id: 1,
			// 	name: "John",
			// 	lastName: "Doe",
			// 	role: "EDITOR",
			// 	email: "john.doe@rescue.org",
			// 	isActive: true,
			// 	createdAt: "2020-04-23T10:00:00.000Z",
			// 	updatedAt: "2020-08-21T07:15:11.000Z",
			// 	instanceId: null,
			// 	instance: null,
			// }
		},

		// SCENES ////////////
		providers: {
			list: null,
		},
		providerCategories: {
			list: null,
		},
		regions: {
			activeTab: 'country',
			citiesList: null,
			countriesList: null,
			regionsList: null,
		},
		services: {
			list: null,
		},
		serviceCategories: {
			list: null,
		},
		users: {
			list: null,
		},
		// SCENES ////////////

		skeleton: {
			resultsPerPage: 10,
			showFilter: false,
			sidebarnav: {
				isOpen: true
			}
		},

		settings: {
			language: 'en',
			logoutTimeout: 10,
			toggleIDs: true,
		}
	},

	logger: {
		requests: true,
		// reducers: true,
	},

	languages: [
		{ id: 'ar' }, // Arabic
		{ id: 'de' }, // Deutsch
		{ id: 'en' }, // English
		{ id: 'fa' }, // Farsi
		{ id: 'fr' }, // French
		{ id: 'it' }, // Italian
		{ id: 'ur' }, // Urdu
		{ id: 'es' }, // Spanish
		{ id: 'sw' }, // Swahili
		{ id: 'ki' }, // Kirundi
		{ id: 'ps' }, // Pastho
		{ id: 'fa-AF' }, // Dari
		{ id: 'uk' }, // Ukrainian 
		{ id: 'ru' }, // Russian  
		{ id: 'hu' }, // Hungarian  

		{ id: 'cs' }, // Czech  

	],

	uiLanguages: [
		{ id: 'en' }, // English
		{ id: 'es' }, // Spanish
	],

	roles: [
		'ADMIN',
		'EDITOR',
		'SUPER',
	],

	routes: {
		// SCENES ////////////
		services: '/services',
		providers: '/providers',
		regions: '/regions',
		serviceCategories: '/service-categories',
		providerCategories: '/provider-categories',
		users: '/users',
		settings: '/settings',
		resetPassword: '/resetPassword',
		support: '/support',
		sync: '/sync',
		// SCENES ////////////

		// SUBSCENES /////////
		countries: '/countries', // backend only
		cities: '/cities', // backend only
		dummy: '/dummy',

		// OTHER
		instances: '/instances', // backend only
	},
}

export default settings
