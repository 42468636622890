import actions from './actions';
import { helpers } from '../../helpers';
import settings from '../../shared/settings';

const logger = new helpers.Logger('Regions.reducers', settings.logger.reducers);

const regionsReducers = (state = settings.defaultState.regions, action) => {
	switch (action.type) {
		case actions.types.cacheCitiesList:
			logger.redux('cacheCitiesList', 'payload', action.payload);
			return { ...state, citiesList: action.payload };

		case actions.types.cacheCountriesList:
			logger.redux('cacheCountriesList', 'payload', action.payload);
			return { ...state, countriesList: action.payload };

		case actions.types.cacheRegionsList:
			logger.redux('cacheRegionsList', 'payload', action.payload);
			return { ...state, regionsList: action.payload };

		case actions.types.setActiveTab:
			logger.redux('setActiveTab', 'payload', action.payload);
			return { ...state, activeTab: action.payload };

		default:
			return state;
	}
};

export default regionsReducers;
