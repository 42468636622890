import actions from './actions';
import { helpers } from '../../../helpers';
import settings from '../../../shared/settings';

const logger = new helpers.Logger('Skeleton.reducers', settings.logger.reducers);

const skeletonReducers = (state = settings.defaultState.skeleton, action) => {
	switch (action.type) {
		case actions.types.setResultsPerPage:
			logger.redux('setResultsPerPage', 'payload', action.payload);
			return { ...state, resultsPerPage: action.payload };

		case actions.types.setShowFilter:
			logger.redux('setShowFilter', 'payload', action.payload);
			return { ...state, showFilter: action.payload };

		case actions.types.setSidebarNavOpen:
			logger.redux('setSidebarNavOpen', 'payload', action.payload);
			const sidebarnav = { ...state.sidebarnav, isOpen: action.payload };
			return { ...state, sidebarnav };

		default:
			return state;
	}
};

export default skeletonReducers;
