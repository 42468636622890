// Libs

// Local
import settings from '../shared/settings'
import store from '../shared/store'

// const logger = new Logger('helpers.api', settings.logger.requests)
const METHODS = { GET: 'GET', POST: 'POST', PUT: 'PUT', DELETE: 'DELETE' }

/**
 * @function
 * @description Request wrapper. Isolates ugly promisified code, using simple callbacks for reject/success/error
 */
const request = (method, body, endpoint, reject, success, error) => {
	/**
	 * @method
	 * @description 
	 */
	const composeHeader = (options) => {
		const authorization = { 'Authorization': options.token }
		const cookie = { 'Cookie': options.cookie }
		const jsonType = { 'Content-Type': 'application/json' }
		const language = options.language || 'en'

		return {
			'Accept': 'application/json',
			...(options.token && authorization),
			...(options.cookie && cookie),
			...(!options.isFile && jsonType), // If it's a file, fetch autocompletes Content-Type field, otherwise assumes json by default
			'Content-Language': language,
			'X-Requested-With': 'XMLHttpRequest', // for security reasons
			// 'Access-Control-Allow-Origin': '*',
		}
	}

	/**
	 * @method
	 * @description Checks if the body is a FormData type
	 */
	const isFile = body => body && body.toString() === '[object FormData]'

	/**
	 * @method
	 * @description Ignores the response object for DELETE method, otherwise returns the json 
	 */
	const parseStatus = res => {
		if (res.status === 200) {
			return method === METHODS.DELETE ? success('OK') : res.json()
		}

		console.log('request', 'reject', res)
		reject(res)
		return Promise.reject(res)
	}

	/**
	 * @method
	 * @description 
	 */
	const resolveResponse = res => {
		console.log('request', 'success', res)
		res && success(res)
	}

	/**
	 * @method
	 * @description 
	 */
	const parseError = err => {

		console.log('request', err.status === undefined ? 'Endpoint unreachable' : 'error', err)
		// logger.error('request', err.status === undefined ? 'Endpoint unreachable' : 'error', err)

		if (err.text) {
			err.text().then(errmsg => {
				err.message = errmsg
				error(err)
				return err
			})
		} else {
			error(err.json)
		}

	}


	// BODY ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

	// an object with body property (POST & PUT only) otherwise false
	const fetchBody = ((method === METHODS.POST || method === METHODS.PUT) && { 'body': isFile(body) ? body : JSON.stringify(body) })
	// redux state object
	const reduxState = store.getState()
	// options for composeHeader
	const headerOptions = {
		token: reduxState.login.token,
		language: reduxState.settings.language,
		isFile: isFile(body),
	}
	// options for fetch
	const fetchOptions = {
		headers: composeHeader(headerOptions),
		...fetchBody,
		method
	}

	console.log('request', method, endpoint)

	return fetch(endpoint, fetchOptions).then(parseStatus).then(resolveResponse).catch(parseError)
}


const crudFactory = (backendUrl, endpoint) => ({
	/**
	 * @method
	 * @description 
	 */
	getAll: (body, reject, success, error) => {
		const url = `${backendUrl}${endpoint}`
		return request(METHODS.GET, body, url, reject, success, error)
	},

	/**
	 * @method
	 * @description 
	 */
	getOne: (id, reject, success, error) => {
		const url = `${backendUrl}${endpoint}/${id}`
		return request(METHODS.GET, null, url, reject, success, error)
	},

	/**
	 * @method
	 * @description Saves/updates
	 */
	save: (body, reject, success, error) => {
		const method = body.id ? METHODS.PUT : METHODS.POST
		const url = `${backendUrl}${endpoint}`
		return request(method, body, url, reject, success, error)
	},

	/**
	 * @method
	 * @description updates
	 */
	update: (body, reject, success, error) => {
		const url = `${backendUrl}${endpoint}`
		return request(METHODS.PUT, body, url, reject, success, error)
	},

	/**
	 * @method
	 * @description 
	 */
	remove: (id, reject, success, error) => {
		const url = `${backendUrl}${endpoint}/${id}`
		return request(METHODS.DELETE, null, url, reject, success, error)
	},

	zendeskSync: (countryId, categoryId, permissionGroupId, preferredLanguage, locale, reject, success, error) => {
		const url = `${backendUrl}${endpoint}/sync/${countryId}/?categoryId=${categoryId}
					&permissionGroupId=${permissionGroupId}&preferredLanguage=${preferredLanguage}&locale=${locale}`
		return request(METHODS.GET, null, url, reject, success, error)
	},

	boxSync: (countryId, reject, success, error) => {
		const url = `${backendUrl}/services/export-file/${countryId}`
		return request(METHODS.GET, null, url, reject, success, error)
	},

	/**
	 * @method
	 * @description Saves/updates
	 */
	resetPassword: (body, reject, success, error) => {
		const url = `${backendUrl}${endpoint}/resetPassword`
		return request(METHODS.POST, body, url, reject, success, error)
	},

	getTransifexStatus: (id, reject, success, error) => {
		const url = `${backendUrl}/services/transifex-status/${id}`
		return request(METHODS.GET, null, url, reject, success, error)
	}
})


const api = {
	crudFactory,
	request,
}

export { api }
