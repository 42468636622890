// Local
import { helpers } from '../../helpers';
import settings from '../../shared/settings';

let api = {
	users: helpers.api.crudFactory(settings.backend.url, settings.routes.users),
	instances: helpers.api.crudFactory(settings.backend.url, settings.routes.instances),
};

export default api;
