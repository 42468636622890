// Libs
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'

// Local
import AppRouter from './shared/router'
import './App.css'

library.add(fas as any) // FIXME: avoid loading the whole lib

class App extends Component {
	render() {
		return (
			<div className='App'>
				<Helmet>
					<title>Service Map CMS</title>
					<link rel='shortcut icon' href={'./favicon.ico'} />
				</Helmet>

				<AppRouter />
			</div>
		)
	}
}

export default App
