import { createContext, Dispatch, SetStateAction } from "react";
import type { instances } from "../../../serverts/prisma/prisma-client-js/index";

export interface InstanceSelectorContextInterface {
	instance: instances;
	setInstance: Dispatch<SetStateAction<instances>>;
	isDisabled: boolean;
	setIsDisabled: Dispatch<SetStateAction<boolean>>;
}

export const InstanceSelectorContext = createContext<InstanceSelectorContextInterface | null>(null);