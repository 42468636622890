import { data } from './data'

export const app = {
	auth: {
		user: null as User,
	},
	language: 'en',
	data,

}

